import React, { useState, useEffect } from 'react';
import Uploader from '../photos/uploadPhotos';
import { useLocation } from '@reach/router';
import { getWeddingDetail, updateWeddingDetails } from '../../actions/weddingAction';
import { getLoggedInUserId } from '../../actions/authAction';
import { toast } from 'react-toastify';
import ViewPhotos from '../photos/viewPhotos';
import './weddingStyles.css';

const WeddingCardUploader = () => {
  const [weddingCardUrls, setWeddingCardUrls] = useState([]);
  const [weddingVideoUrls, setWeddingVideoUrls] = useState([]);
  const location = useLocation();
  const url = decodeURI(location.search);
  const params = new URLSearchParams(url);
  const weddingId = params.get('weddingId');

  const fetchWeddingDetails = async () => {
    try {
      const data = await getWeddingDetail({ weddingId });
      setWeddingCardUrls(data?.event?.wedding_card?.map((photo) => photo.url));
      setWeddingVideoUrls(data?.event?.invitation_video?.url ? [data?.event?.invitation_video?.url] : []);
      return data?.event;
    } catch (error) {
      console.error('Error fetching wedding details:', error);
      toast.error('Error fetching wedding details');
      return null;
    }
  };

  useEffect(() => {
    fetchWeddingDetails();
  }, [weddingId]);

  const updateWeddingDetailsData = async (existingWeddingData, updatedData, type) => {
    try {
      let updatedDataObj = { ...existingWeddingData };
      if (type === 'photo') {
        updatedDataObj.wedding_card = updatedData;
      } else if (type === 'video') {
        updatedDataObj.invitation_video = updatedData;
      }
      const result = await updateWeddingDetails(updatedDataObj, weddingId);
      if (result?.status) {
        if (type === 'photo') {
          setWeddingCardUrls(result?.event?.wedding_card?.map((photo) => photo.url) || []);
        } else if (type === 'video') {
          setWeddingVideoUrls(result?.event?.invitation_video?.url ? [result?.event?.invitation_video?.url] : []);
        }
      }
    } catch (error) {
      console.error('Error updating wedding details:', error);
      toast.error('Error updating wedding details');
    }
  };

  const handleUploadSuccess = async (url, md5Code, type) => {
    try {
      let existingWeddingData = await fetchWeddingDetails();
      let updatedData;

      const newEntry = {
        url,
        uploaded_by: getLoggedInUserId(),
        uploaded_at: Math.floor(Date.now() / 1000),
        md5sum: md5Code,
      };

      if (type === 'photo') {
        updatedData = [...(existingWeddingData?.wedding_card || []), newEntry];
      } else if (type === 'video') {
        updatedData = newEntry;
      }

      updateWeddingDetailsData(existingWeddingData, updatedData, type);
      toast.success(`शादी का ${type === 'photo' ? 'कार्ड' : 'निमंत्रण वीडियो'} सफलतापूर्वक अपलोड किया गया`);
    } catch (error) {
      toast.error('Error updating wedding details');
    }
  };

  const handleDeleteMedia = async (index, type) => {
    const confirmDelete = window.confirm(`क्या आप इस शादी के ${type} को डिलीट करना चाहते हैं?`);
    if (!confirmDelete) return;
    try {
      const existingWeddingData = await fetchWeddingDetails();
      let updatedData;

      if (type === 'photo' && index >= 0 && index < existingWeddingData?.wedding_card?.length) {
        updatedData = existingWeddingData?.wedding_card?.filter((_, i) => i !== index);
      } else if (type === 'video') {
        updatedData = null;
      }

      updateWeddingDetailsData(existingWeddingData, updatedData, type);
      toast.success(`${type.charAt(0).toUpperCase() + type.slice(1)} Deleted Successfully`);
    } catch (error) {
      toast.error('Error updating wedding details after delete');
    }
  };

  const handleClick = () => {
    const url = `/weddings/createSubEvents?weddingId=${weddingId}`;
    window.location.href = url;
  };
  return (
    <>
      <div>
        <ViewPhotos
          photos={weddingCardUrls}
          heading="शादी कार्ड फ़ोटो"
          showCloseBtn={false}
          showDeleteBtn={true}
          onDeleteButtonClick={(index) => handleDeleteMedia(index, 'photo')}
        />
        <h2>शादी कार्ड अपलोड करें</h2>
        <Uploader
          weddingId={weddingId}
          onUploadSuccess={(url, md5Code) => handleUploadSuccess(url, md5Code, 'photo')}
        />
        {weddingVideoUrls.length > 0 ? (
          <div>
            <ViewPhotos
              photos={weddingVideoUrls}
              heading="शादी का निमंत्रण वीडियो"
              showCloseBtn={false}
              showDeleteBtn={true}
              onDeleteButtonClick={(index) => handleDeleteMedia(index, 'video')}
              isVideo={true}
            />
            <p>वीडियो पहले से मौजूद है. नया वीडियो अपलोड करने के लिए पहले इसे डिलीट करें.</p>
          </div>
        ) : (
          <>
            <h2>शादी का निमंत्रण वीडियो अपलोड करें</h2>
            <Uploader
              weddingId={weddingId}
              isVideo={true}
              onUploadSuccess={(url, md5Code) => handleUploadSuccess(url, md5Code, 'video')}
            />
          </>
        )}
      </div>
      <button className="continue-button" onClick={handleClick}>
        Continue
      </button>
    </>
  );
};

export default WeddingCardUploader;
